<template>
  <form action="" autocomplete="off" v-on:submit.prevent="submit">
  <div class="row">
    <div class="col-sm-10">
      <div class="alert alert-info" role="alert">
        <i class="fa fa-info-circle"></i>
        <span>
        {{ $tc('two_factor_authentication_explanation') }}
        </span>
        <span v-if="otp_required">
        <strong>{{ $tc('required_resource_configuration') }}</strong>
        </span>
        <span v-else>
        <strong>{{ $tc('optional_resource_configuration') }}</strong>
        </span>
      </div>
    </div>
    <div class="col-sm-2">
      <button
        class="btn btn-primary pull-right"
        @click.stop.prevent="onUpdateSecurity"
        :disabled="busy"
      >
        {{ $t("save") }}
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <h2>{{ $tc('two_factor_authentication') }}</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4" v-if="site_email_enabled">
      <Box class="otp-box-container" type="primary" solid>
        <div class="box-body">
          <h4>
            <i class="fa fa-envelope"></i>
            {{ $tc('two_factor_authentication_email') }}
          </h4>
          <label for="input_email">
            <input type="checkbox" id="input_email" v-model="email_enabled" />
            {{ $tc('enabled') }}
          </label>
          <p>{{ $tc('two_factor_authentication_email_description') }}</p>
        </div>
      </Box>
    </div>
    <div class="col-sm-4" v-if="site_app_enabled">
      <Box class="otp-box-container" type="primary" solid>
        <div class="box-body">
          <h4>
            <i class="fa fa-mobile"></i>
            {{ $tc('two_factor_authentication_app') }}
          </h4>
          <label for="input_app">
            <input type="checkbox" id="input_app" v-model="app_enabled" />
            {{ $tc('enabled') }}
          </label>
          <p>{{ $tc('two_factor_authentication_app_description') }}</p>
        </div>
      </Box>
    </div>
    <div class="col-sm-4" v-if="site_app_enabled && app_enabled">
      <Box class="otp-box-container" type="primary" solid>
        <div class="box-body">
          <p>{{ $tc('two_factor_authentication_use_this_qrcode') }}</p>
          <p><i>{{ $tc('two_factor_authentication_suggestions') }}</i></p>
          
          <div class="text-center">
            <Qrcode
              ref="qrcode"
              :forceDimensions="false"
              :content="qrcode_content"
              :styleCustom="'max-width: 300px; max-height: 300px; width: 90%; height: 90%;'"
              @state="isReady = $event == 'ready'"
              ></Qrcode>
          </div>
        </div>
      </Box>
    </div>
  </div>
  </form>
</template>

<script>
import Box from "@/components/widgets/box";
import MixinAlert from "@/project/mixin-alert.js";
import errorMessages from "@/i18n/errors.js";
import Qrcode from "./widgets/qrcode.vue";

export default {
  name: "UserSecurityForm",
  mixins: [MixinAlert],
  components: {
    Box,
    Qrcode
},
  i18n: {
    messages: errorMessages
  },
  data() {
    return {
      busy: false,
      loggedUser: null,
      otp_required: null,
      site_app_enabled: null,
      site_email_enabled: null,
      qrcode_image_url: null,
      app_enabled: false,
      email_enabled: false
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
    onUpdateSecurity() {
      if (this.busy) return;
      this.busy = true;
      let payload = {
        user_profile: {
          otp_config: this.$root.config.references.otp.otp_config
        }
      };
      payload.user_profile.otp_config.app_enabled = this.app_enabled;
      payload.user_profile.otp_config.email_enabled = this.email_enabled;

      this.$store.dispatch("user/updateProfile", payload).then((ret) => {
        this.busy = false;
        if (this.validateSaveResponse(ret)) {
          this.showAlert(() => {});
        } else {
          this.showAlert();
        }
      });
    },
    submit() {
      if (this.busy) return;
    },
  },
  mounted() {
    this.loggedUser = this.$store.getters["user/loggedUser"] || {};
    this.otp_required = this.$root.config.references.otp.otp_config.required;
    this.site_app_enabled = this.$root.config.references.otp.otp_config.app_enabled;
    this.site_email_enabled = this.$root.config.references.otp.otp_config.email_enabled;
    
    if (this.otp_required && !(this.site_app_enabled || this.site_email_enabled)) {
      this.site_email_enabled = true;
    }

    let secret = this.loggedUser.user_profile.otp_config.secret;
    let issuer_name = this.$root.config.references.otp.otp_config.issuer_name;
    
    this.qrcode_content = 'otpauth://totp/'+this.loggedUser.email+'?secret='+secret+'&issuer='+issuer_name;

    if (this.loggedUser.user_profile) {
        this.app_enabled = this.loggedUser.user_profile.otp_config.app_enabled;
        this.email_enabled = this.loggedUser.user_profile.otp_config.email_enabled;
    }
  }
};
</script>

<style scoped>
.box.box-primary.box-solid {
  border-top: 5px solid #3c8dbc;
}
</style>