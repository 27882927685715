<template>
  <section>
    <div class="container">
      <div class="box box-warning">
        <div class="nav-tabs-custom">
          <ul class="nav nav-tabs pull-right ui-sortable-handle">
            <li class="pull-left header">
              <i class="fa fa-user"></i> {{ $t("user_profile") }}
            </li>
          </ul>
          <div class="tab-content panel-content">
            <div id="data-tab-1" class="tab-pane active">
              <UserProfileForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import UserProfileForm from "@/components/user-profile-form";
export default {
  name: "DashboardUserProfile",
  components: {
    UserProfileForm
  },
  mounted() {
    this.$root.$emit("controlSidebar:setContent", null);
  }
};
</script>

<style scoped></style>
