<template>
  <div class="info-box" :class="boxClass">
    <span :class="['info-box-icon', iconClass]">
      <Icon :name="icon" width="45" height="45" />
    </span>
    <div class="info-box-content">
      <span class="info-box-text">
        {{ text }}<ToolTip
          icon="fa fa-info-circle"
          :title="textTooltip"
          v-if="textTooltip"
        /><span v-if="textStrong">:&nbsp;</span>
        <strong>{{ textStrong }}</strong>
        </span>
      <span
        class="info-box-number"
        :style="!showProgress ? 'margin-top: 6px' : ''"
        >
        <template v-if="numberIcon">
          <Icon :name="numberIcon" />
        </template>
        {{ number }}<span v-if="numberStrong">:&nbsp;</span>
        <strong>{{ numberStrong }}</strong>
        </span
      >
      <div class="progress" v-if="showProgress">
        <div class="progress-bar" :style="`width: ${progress}%`"></div>
      </div>
      <span
        class="progress-description"
        :style="!showProgress ? 'margin-top: 6px' : ''"
        v-if="progressDescription"
      >
        <template v-if="progressDescriptionIcon">
          <Icon :name="progressDescriptionIcon" />
        </template>
        {{ progressDescription }}
        <span v-if="progressDescriptionStrong">:&nbsp;</span>
        <strong>{{ progressDescriptionStrong }}</strong>
        <span v-if="progressDescriptionItalic">&nbsp;-&nbsp;</span>
        <i>{{ progressDescriptionItalic }}</i>
      </span>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/icons/icon";
import ToolTip from "@/components/tooltip.vue";

export default {
  name: "InfoCard",
  components: {
    Icon,
    ToolTip
  },
  props: {
    icon: {
      type: String,
      required: false,
      default: "fa fa-bookmark-o"
    },
    text: {
      type: String,
      required: false,
      default: "Info"
    },
    textTooltip: {
      type: String,
      required: false
    },
    textStrong: {
      type: String,
      required: false
    },
    number: {
      type: [String, Number],
      required: false,
      default: '0'
    },
    numberIcon: {
      type: String,
      required: false
    },
    numberStrong: {
      type: String,
      required: false
    },
    progress: {
      type: String,
      required: false,
      default: null
    },
    forceShowProgress: {
      type: Boolean,
      required: false,
      default: false
    }, 
    progressDescription: {
      type: String,
      required: false
    },
    progressDescriptionIcon: {
      type: String,
      required: false
    },
    progressDescriptionStrong: {
      type: String,
      required: false
    },
    progressDescriptionItalic: {
      type: String,
      required: false
    },
    iconClass: {
      type: String
    },
    boxClass: {
      type: String,
      required: false
    }
  },
  computed: {
    showProgress() {
      if (this.forceShowProgress) return true;
      if (this.progress == null) return false;
      let temp = this.progress.replace(/\D/g, "");
      if (temp != "") return true;
      return false;
    }
  }
};
</script>

<style scoped>
.info-box-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
