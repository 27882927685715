<template>
  <div 
    class="box clicable"
    :class="{
      'box-default': !computedCanDeal,
      'box-primary': computedCanDeal && !isSelectedPlan,
      'box-success': computedCanDeal && isSelectedPlan
      }"
    @click="onClick"
  >
    <div class="box-header text-center">
      <h4 class="box-title" :class="{'text-green': isSelectedPlan}">
        <strong>{{ plan.title.toUpperCase() }}</strong>
      </h4>
    </div>
    <div class="box-body">
        <div v-if="plan.image" class="text-center">
          <img v-if="plan.image" :src="plan.image" />
        </div>
        <div class="plan-description text-italic text-center">{{ plan.description }}</div>
        <hr/>
        <span v-for="(item,ix) in plan.highlight_resource_list" :key="'icon_'+ix">
          <Icon name="fa fa-check-circle text-blue" />&nbsp;
          <strong>{{ item }}</strong>
          <br/>
        </span>
        <span v-for="(item,ix) in plan.resource_list" :key="ix">
          <Icon name="fa fa-check-circle text-green" />&nbsp;
          {{ item }}
          <br/>
        </span>
    </div>
    <div class="box-footer text-center">
      <div class="price" data-testid="price">
        <template v-if="computedCanDeal && computedPrice == 0">
          <span class="currency">{{ $t("free") }}</span>
        </template>
        <template v-if="computedCanDeal && computedPrice > 0">
          <span class="sign">{{ currentSign }}&nbsp;</span>
          <span class="currency">{{ currency(computedPrice) }}</span>
          <span class="cent">,{{ cents(computedPrice) }}</span>
          <span class="month" v-if="computedCanDeal"
            >&nbsp;/&nbsp;{{
              $t(selectedCommitment == "monthly" ? "month" : "year")
            }}</span
          >
        </template>
      </div>

      <div
        class="limit-title"
        data-testid="range"
        v-if="minimumDataRange && minimumDataRange.quota > selectedDataRange"
      >
        {{ $t("starts_with") }}
        {{ minimumDataRange.quota }}
        {{ $tc("data", 2) }}
      </div>
      <div
        class="limit-title"
        data-testid="range"
        v-if="minimumDataRange && minimumDataRange.quota <= selectedDataRange"
      >
        {{ $t("up_to") }}
        {{ minimumDataRange.quota }}
        {{ $tc("data", 2) }}
      </div>
      <div
        class="limit-title"
        data-testid="range"
        v-if="!minimumDataRange && maximumDataRange"
      >
        {{ $t("up_to") }}
        {{ maximumDataRange.quota }}
        {{ $tc("data", 2) }}
      </div>
      <div
        class="limit-title"
        data-testid="range"
        v-if="!minimumDataRange && !maximumDataRange"
      >
        {{ $t("not_available") }}
      </div>

      <div class="selected-plan-text">
        <template v-if="isSelectedPlan && isContractedPlan">
          <h4>
            <Icon name="fa fa-arrow-circle-right" />
            &nbsp;
            {{ $tc("your_current_plan") }}
          </h4>
        </template>
        <template v-if="isSelectedPlan && !isContractedPlan">
          <h4>
            <Icon name="fa fa-check-circle" />
            &nbsp;
            {{ $tc("your_new_plan") }}
          </h4>
        </template>
        <template v-if="!isSelectedPlan && isContractedPlan">
          <h4>
            <Icon name="fa fa-arrow-right" />
            &nbsp;
            {{ $tc("your_current_plan") }}
          </h4>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/icons/icon.vue";

export default {
  name: "UserPlanInfoBox",
  components: {
    Icon
  },
  props: {
    iplan: {
      type: Number,
      required: true
    },
    plan: {
      type: Object,
      required: true
    },
    selectedPlanId: {
      type: Number,
      required: true
    },
    selectedCommitment: {
      type: String,
      required: true
    },
    selectedDataRange: {
      type: Number,
      required: true
    },
    canDeal: {
      type: Function,
      required: true
    },
    price: {
      type: Function,
      required: true
    },
    markContractedPlan: {
      type: Boolean,
      required: false,
      default: () => {return true}
    }
  },
  data() {
    return {
      contractedPlanId: null
    }
  },
  watch: {
    plan: {
      handler() {
        if (this.contractedPlanId != null) return;
        this.contractedPlanId = this.markContractedPlan ? this.selectedPlanId : -1;
      },
      immediate: true
    }
  },
  computed: {
    computedCanDeal() {
      return this.canDeal(this.iplan);
    },
    computedPrice() {
      if (!this.computedCanDeal) return -1;
      let value = this.price(this.iplan);
      if (value == -1) {
        if (this.minimumDataRange && this.selectedDataRange < this.minimumDataRange.quota) {
          value = this.minimumDataRange.value;
        } else {
          value = this.maximumDataRange.value;
        }
      }
      return value;
    },
    currentSign() {
      return this.$root.config.currency_sign || "R$";
    },
    isSelectedPlan() {
      return this.selectedPlanId == this.plan.id;
    },
    isContractedPlan() {
      return this.contractedPlanId == this.plan.id;
    },
    maximumDataRange() {
      if (!this.plan || !this.plan.monthly_price) return null;
      return this.plan.monthly_price[this.plan.monthly_price.length-1];
    },
    minimumDataRange() {
      if (!this.plan || !this.plan.monthly_price) return null;
      let result = this.plan.monthly_price.filter(v => v.quota >= this.selectedDataRange);
      if (!result) return null;
      return result[0];
    }
  },
  methods: {
    currency(value) {
      return this.$utils.formatMoney(value, false).split(",")[0];
    },
    cents(value) {
      return this.$utils.cents(value + "");
    },
    onClick() {
      if (!this.canDeal(this.iplan)) return;
      this.$emit('plan-selected', this.iplan);
    }
  }
};
</script>

<style scoped>
.box.box-default {
  border: solid 1px #d2d6de;
  border-top: solid 5px #d2d6de;
}
.box.box-primary {
  border: solid 1px #3c8dbc;
  border-top: solid 5px #3c8dbc;
}
.box.box-success {
  border: solid 1px #00a65a;
  border-top: solid 5px #00a65a;
}
.box.box-default .price, .box.box-default .selected-plan-text {
  color: #d2d6de;
}
.box.box-primary .price, .box.box-primary .selected-plan-text {
  color: #3c8dbc;
}
.box.box-success .price, .box.box-success .selected-plan-text {
  color: #00a65a;
}
.text-italic {
  font-style: italic;
}
.clicable:hover {
  cursor: pointer;
}
.price {
  font-weight: bold;
}
.sign, .month, .cent {
  font-size: 12pt;
}
.currency {
  font-size: 18pt;
}
.limit-title {
  font-weight: bold;
}
</style>
