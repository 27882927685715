<template>
  <section class="self no-select" data-testid="plan-pricing">
    <div class="row config-row">
      <div class="hidden-xs hidden-sm col-md-3 text-right">
        <label>{{ $t("periodicity") }}</label>
        <ToolTip
          icon="fa fa-info-circle"
          :title="$t('save_on_the_annual_plan')"
        />
      </div>
      <div class="col-xs-12 col-md-3 text-left">
        <div class="hidden-xs hidden-sm commitmentToggle">
          <UserPlanCommitmentToggle v-model="selectedCommitment" />
        </div>
        <div class="hidden-lg hidden-md">
          <label for="selectedCommitment">{{ $t("periodicity") }} </label>
          <select
            class="form-control"
            v-model="commitment"
            id="selectedCommitment"
          >
            <option value="monthly">{{ $t("monthly") }}</option>
            <option value="yearly">{{ $t("yearly") }}</option>
          </select>
        </div>
      </div>
      <div class="hidden-xs hidden-sm col-md-3 text-right">
        <label>{{ $t("data_amount") }}</label>
        <ToolTip
          icon="fa fa-info-circle"
          :title="
            $t('hints.maximum_number_of_data_that_can_be_stored_by_the_portal')
          "
        />
      </div>
      <div class="col-xs-12 col-md-2 text-left">
        <div class="hidden-xs hidden-sm">
          <UserPlanDataSlider
            v-model="selectedQuotaIndex"
            :items="quotaOptions"
          />
        </div>
        <div class="hidden-lg hidden-md">
          <label for="selectedQuotaIndex">{{ $t("data_amount") }} </label>
          <select
            class="form-control"
            v-model="selectedQuotaIndex"
            id="selectedQuotaIndex"
          >
            <option v-for="(item, ix) in quotaOptions" :key="ix" :value="ix">
              {{ $t("up_to") }} {{ item }} {{ $tc("data", 2) }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12" v-if="plans">
        <UserPlanList
          :plans="plans"
          :selectedPlanId="selectedPlanId"
          :selectedCommitment="selectedCommitment"
          :selectedDataRange="selectedDataRange"
          :canDeal="canDeal"
          :price="price"
          :markContractedPlan="false"
          @plan-selected="onChange"
        />
      </div>
    </div>
    <div class="row">
      <div class="hidden-xs hidden-sm col-md-1"></div>
      <div class="col-md-4" style="margin-bottom: 10px">
        <div class="row">
          <div class="col-xs-6 col-md-5">
            <Icon name="fa fa-arrow-right" />&nbsp; {{ $t("plan") }}:
          </div>
          <div class="col-xs-6 col-md-7 text-green">
            <label>{{ selectedPlanInfo }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-6 col-md-5">
            <Icon name="fa fa-refresh" />&nbsp; {{ $t("periodicity") }}:
          </div>
          <div class="col-xs-6 col-md-7 text-green">
            <label>{{ $t(commitment) }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-6 col-md-5">
            <Icon name="fa fa-usd" />&nbsp; {{ $t("value") }}:
          </div>
          <div class="col-xs-6 col-md-7 text-green">
            <label>{{ priceInfo }}</label>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-md-3" :class="{ 'margin-top-10': !isTest }">
        <Terms v-if="showTerms" v-model="terms" :fontSizeBigger="false" />
        <!-- <Checkbox
            v-if="isTest"
            id="force_end_test"
            v-model="force_end_test"
            labelClass="no-select"
            :title="$t('hints.force_end_test')"
          >
            {{ $t("titles.force_end_test") }}
            <ToolTip :title="$t('hints.force_end_test')" />
          </Checkbox> -->
      </div>
      <div class="col-xs-12 col-md-3">
        <div class="buy-container" style="margin-top: 10px">
          <template v-if="!busy">
            <button
              class="btn btn-primary btn-block contract"
              :disabled="showTerms && !terms"
              @click.stop.prevent="onBuy"
            >
              <Icon name="fa fa-check" />&nbsp;
              <span v-if="buttonText">{{ buttonText.toUpperCase() }}</span>
              <span v-else>{{ $t("do_contract").toUpperCase() }}</span>
            </button>
          </template>
          <template v-if="busy">
            <button
              class="btn btn-primary btn-block contract"
              disabled="disabled"
            >
              <Icon name="fa fa-refresh fa-spin" />&nbsp;
              {{ $t("please_wait_no_timeout") }}
            </button>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Pricing from "@/assets/dashboard/pricing.json";
import UserPlanDataSlider from "@/components/user-plan/user-plan-data-slider.vue";
import UserPlanList from "@/components/user-plan/user-plan-list.vue";
import UserPlanCommitmentToggle from "@/components/user-plan/user-plan-commitment-toggle.vue";
import Terms from "@/components/terms.vue";
import ToolTip from "@/components/tooltip.vue";
import Icon from "@/components/icons/icon.vue";
// import Checkbox from "@/components/base/checkbox";
import { contractStateConst, contractPlanConst } from "@/assets/constants.js";

export default {
  name: "UserPlanPricing",
  components: {
    UserPlanDataSlider,
    UserPlanList,
    UserPlanCommitmentToggle,
    Terms,
    ToolTip,
    Icon,
    // Checkbox
  },
  props: {
    payload: {
      type: Object,
      required: false,
      default: () => { }
    },
    showTerms: {
      type: Boolean,
      required: false,
      default: () => {
        return true;
      }
    },
    buttonText: {
      type: String,
      required: false
    },
    markContractedPlan: {
      type: Boolean,
      required: false,
      default: () => {
        return true;
      }
    },
    busy: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      }
    }
  },
  data: function() {
    return {
      plans: [],
      selectedPlanIndex: 1,
      selectedQuotaIndex: 0,
      quotaOptions: [],
      selectedCommitment: "monthly",
      terms: false,
      force_end_test: false
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/loggedUser"] || null;
    },
    isDemo() {
      return (
        (this.user && this.user.contract && this.user.contract.is_demo) || false
      );
    },
    isRoot() {
      return (this.user && this.user.is_root) || false;
    },
    isTest() {
      return this?.contract?.contract_state?.id == contractStateConst.TEST;
    },
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    userPlan() {
      return (
        (this?.contract &&
          this?.contract?.billing &&
          this?.contract?.billing?.plan) ||
        null
      );
    },
    isMigrating() {
      return (
        this?.contract &&
        !this?.contract?.is_demo &&
        !this?.contract?.pricing_by_data_range
      );
    },
    priceMultiplier() {
      return this.selectedCommitment == "monthly" ? 1 : 10;
    },
    selectedDataRange() {
      if (!this.quotaOptions || !this.quotaOptions.length) return -1;
      if (this.selectedQuotaIndex < 0) return -1;
      let selectedOption = this.quotaOptions[this.selectedQuotaIndex];
      if (!selectedOption) return -1;
      return selectedOption;
    },
    commitment() {
      return this?.selectedCommitment;
    },
    contractPlanIndex() {
      return (this.plans || []).findIndex(
        ({ id }) => id == (this?.contract?.contract_plan?.id || -1)
      );
    },
    selectedPlanId() {
      if (!this?.plans?.length) return -1;
      if (this.selectedPlanIndex == -1) return -1;
      return this.plans[this.selectedPlanIndex].id;
    },
    selectedPlan() {
      if (!this?.plans?.length) return null;
      if (!this.selectedPlanId) return null;
      if (this.selectedPlanIndex == -1) return this.plans[1];
      return this.plans[this.selectedPlanIndex];
    },
    selectedPrice() {
      if (!this?.plans?.length) return -1;
      if (!this?.selectedPlanId) return -1;
      if (!this?.selectedPlan?.monthly_price) return -1;
      if (this.selectedQuotaIndex == -1) return -1;
      let result = this.selectedPlan.monthly_price[this.selectedQuotaIndex]
        .value;
      if (typeof result != "number") return -1;
      return result * this.priceMultiplier;
    },
    priceInfo() {
      if (!this?.plans?.length) return "";
      if (!this.selectedPlanId) return "";
      return this.$utils.formatMoney(this.selectedPrice + "");
    },
    selectedPlanInfo() {
      if (this.selectedPlan) {
        return `${this.selectedPlan.title} + ${this?.selectedDataRange
          } ${this.$tc("data", 2)}`;
      } else if (this?.contract) {
        return `${this?.contract?.contract_plan?.description} + ${this?.contract.maximum_data
          } ${this.$tc("data", 2)}`;
      } else {
        return "";
      }
    },
    usesPaymentGateway() {
      if (!this.$root?.config?.references?.payment_gateway) return false;

      if (this?.contract?.uses_payment_gateway) return true;

      // if (this.isTest && !this.force_end_test) return true;

      if (
        this?.contract?.pricing_by_data_range ||
        this?.contract?.pricing_method != "DATA-RANGE"
      )
        return true;

      return false;
    }
  },
  watch: {
    selectedPlanIndex(n) {
      if (n == -1) return;
      let old_value = this.quotaOptions[this.selectedQuotaIndex];
      this.reloadQuotaOptions(n);
      this.setQuotaIndexByDataRange(old_value);
    },
    commitment() {
      if (this.selectedPlanIndex >= 0) {
        this.onChange(this.selectedPlanIndex);
      }
    },
    selectedQuotaIndex() {
      if (this.selectedPlanIndex >= 0) {
        this.onChange(this.selectedPlanIndex);
      }
    },
    force_end_test(n) {
      this.$emit("forceEndTest", n);
    },
    payload() {
      if (!this.showTerms) this.terms = true;

      this.plans = JSON.parse(
        JSON.stringify(
          this?.contract?.is_white_label ? Pricing.whitelabel : Pricing.regular
        )
      ).filter((i) => {
        return !this?.contract?.id && i?.allowed_onlinee == false ||
          i?.allowed_onlinee == this?.contract?.allowed_onlinee ||
          i?.id == contractPlanConst.FREE
      });

      if (this.plans.length <= 1) {
        this.selectedPlanIndex = 0;
      }

      this.reloadQuotaOptions(this.selectedPlanIndex);

      if (this?.payload?.plan_identity_number) {
        let iplan = this.plans.findIndex(
          ({ id }) => id == this.payload.plan_identity_number
        );
        this.reloadQuotaOptions(iplan);
        this.selectedPlanIndex = iplan;
        this.selectedCommitment = this.payload.is_yearly ? "yearly" : "monthly";
        this.setQuotaIndexByDataRange(this.payload.plan_data_range);
      } else if (this.contractPlanIndex >= 0) {
        this.reloadQuotaOptions(this.contractPlanIndex);
        this.selectedPlanIndex = this.contractPlanIndex;
        this.selectedCommitment = this.contract.is_yearly ? "yearly" : "monthly";
        this.setQuotaIndexByDataRange(this?.contract?.maximum_data || 0);
      }
    }
  },
  methods: {
    canDeal(iplan) {
      if (this.contract && !(this.isRoot || this.isDemo)) {
        return false;
      }
      return true;
    },
    price(iplan) {
      let self = this;
      if (this.selectedDataRange >= 0) {
        let entry = this.plans[iplan].monthly_price.find(
          (i) => self.selectedDataRange == i.quota
        );
        if (entry) {
          return entry.value * this.priceMultiplier;
        }
      }
      return -1;
    },
    reloadQuotaOptions(iplan) {
      this.quotaOptions = [];
      this.plans[iplan].monthly_price.forEach((v) => {
        this.quotaOptions.push(v.quota);
      });
    },
    setQuotaIndexByDataRange(value) {
      let selectedQuotaIndex = this.quotaOptions.findIndex(
        (quota) => (0 || value) <= quota
      );
      if (selectedQuotaIndex >= 0) {
        this.selectedQuotaIndex = selectedQuotaIndex;
      } else {
        this.selectedQuotaIndex = 0;
      }
    },
    onChange(iplan) {
      if (!this.canDeal(iplan)) return;
      this.selectedPlanIndex = iplan;
      this.terms = this.showTerms ? false : this.terms;
      var url = location.href;
      location.href = "#terms";
      history.replaceState(null, null, url);
    },
    onBuy() {
      let self = this;
      let iplan = this.selectedPlanIndex;
      if (this.showTerms && !this.terms) return;
      if (!this.canDeal(iplan)) return;
      self.$emit("upgrade", {
        plan_data_range: self.selectedDataRange,
        plan_identity_number: self.selectedPlan.id,
        allowed_onlinee: self.selectedPlan.allowed_onlinee,
        is_yearly: self.selectedCommitment == "yearly",
        transparent_checkout: self.usesPaymentGateway,
        force_end_test: self.force_end_test
      });
    }
  },
  mounted() {
    if (!this?.plans?.length) {
      this.plans = JSON.parse(
        JSON.stringify(
          this?.contract?.is_white_label ? Pricing.whitelabel : Pricing.regular
        )
      ).filter((i) => {
        return !this?.contract?.id && i?.allowed_onlinee == false ||
          i?.allowed_onlinee == this?.contract?.allowed_onlinee ||
          i?.id == contractPlanConst.FREE
      });

      if (this.payload?.plan_identity_number) {
        let iplan = this.plans.findIndex(
          ({ id }) => id == this.payload.plan_identity_number
        );
        this.reloadQuotaOptions(iplan);
        this.selectedPlanIndex = iplan;
        this.selectedCommitment = this.payload.is_yearly ? "yearly" : "monthly";
        this.setQuotaIndexByDataRange(this.payload.plan_data_range);
      } else {
        this.selectedPlanIndex = 1;
        this.reloadQuotaOptions(this.selectedPlanIndex);
      }
    }
  }
};
</script>

<style scoped>
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
.btn.contract {
  font-size: 14pt;
  border-radius: 30px;
}
.config-row {
  margin-bottom: 10px;
}
.commitmentToggle .toggle {
  position: absolute;
  top: -5px;
}
label {
  margin-bottom: 0;
}

.modal-dialog {
  min-width: 1200px;
}

.margin-top-10 {
  margin-top: 10px;
}

.checkbox {
  margin: 0;
}

@media (max-width: 1720px) {
  .modal-dialog {
    min-width: 1000px;
  }
}
@media (max-width: 1200px) {
  .modal-dialog {
    min-width: 1190px;
  }
}
@media (max-width: 992px) {
  .modal-dialog {
    min-width: 940px;
  }
}
@media (max-width: 768px) {
  .modal-dialog {
    min-width: inherit;
  }
}
</style>
