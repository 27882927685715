<template>
  <div class="terms no-select clicable" v-if="terms_url">
    <span @click="!disabled ? (terms = !terms) : (terms = terms)">
      <i :class="terms ? 'fa fa-check-square-o' : 'fa fa-square-o'"></i>
      {{ $t("i_accept_the") }}
    </span>
    <a v-bind:href="terms_url" data-testid="terms-link" target="_blank">
      <template v-if="privacy_url && privacy_url != terms_url">
        {{ $t("terms_of_use") }}
      </template>
      <template v-else>
        {{ $t("terms_of_use") }} {{ $t("and") }} {{ $t("privacy_policy") }}
      </template>
    </a>
    <template v-if="privacy_url && privacy_url != terms_url">
      {{ $t("and") }}
      <a v-bind:href="privacy_url" data-testid="policy-link" target="_blank">
        {{ $t("privacy_policy") }}
      </a>
    </template>
  </div>
</template>

<script>
export default {
  name: "Terms",
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    terms: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.disabled ? false : this.value;
      }
    },
    terms_url() {
      return this.$root.config.company_terms || "";
    },
    privacy_url() {
      return this.$root.config.company_privacy_policies || "";
    }
  }
};
</script>

<style scoped>
.terms {
  font-size: 10pt;
  font-weight: 600;
}
i.fa {
  min-width: 14px;
}
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
.clicable:hover {
  opacity: 0.8;
  cursor: pointer;
}
</style>
