<script>
import PanelHeaderEquipmentList from "@/components/panel-header-equipment-list.vue";
import MixinAlert from "@/project/mixin-alert.js";
import {contractPlanConst} from "@/assets/constants.js";
import DeviceService from "@/services/device";
import EquipmentDataService from "@/services/equipment-data.js";

function defaultData() {
  return {
    entity: "",
    busy: false,
    items: null,
    parent: null,
    maxResult: 0,
    deleted_id: "",
    sortOrder: null,
    title: "loading",
    nav: null,
    multiSelection: {
      key: "",
      values: []
    }
  };
}
export default {
  name: "DashboardEditPickerBase",
  components: {PanelHeaderEquipmentList},
  mixins: [MixinAlert],
  props: {
    header: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  data: defaultData,
  computed: {
    pagination() {
      return "page_size" in this.$root.config.equipment_selection &&
        this.$root.config.equipment_selection.page_size
        ? true
        : false;
    },
    contract() {
      let user = this.$store.getters["user/loggedUser"];
      return (user && user.contract) || null;
    },
    isFreePlan() {
      return this?.contract?.contract_plan?.id == contractPlanConst.FREE;
    },
    connectorList() {
      return this.$store.getters["dashboard/connectorList"] || [];
    },
    connector() {
      return (
        (this.connectorId &&
          this.connectorList.find(
            ({id}) => parseInt(id) == parseInt(this.connectorId)
          )) ||
        this?.parent?.device?.connector ||
        this?.parent?.connector ||
        null
      );
    },
    connectorId() {
      return parseInt(
        this.connector_id || this.$route.params.connector_id || 0
      );
    },
    deviceId() {
      return parseInt(this.device_id || this.$route.params.device_id || 0);
    },
    dataId() {
      return parseInt(this.data_id || this.$route.params.data_id || 0);
    },
    alarmId() {
      return parseInt(this.alarm_id || this.$route.params.alarm_id || 0);
    },
    isModel() {
      return (this.connector && this.connector.base_model) || false;
    },
    isModelBased() {
      return this?.connector?.base_model_id || false;
    },
    isEmpty() {
      // it does not return true by default. It will returns true after fetch items without succeed instead
      return this.items !== null && this?.items?.length == 0;
    },
    isMQTT() {
      return this?.connector?.protocol?.is_mqtt_protocol || false;
    },
    screenList() {
      return (this.$store.getters["dashboard/screens"] || []).filter(
        ({id}) => id > -1
      );
    },
    nSelected() {
      return (this.multiSelection.values || []).length;
    },
    cloneEnabled() {
      return this.$utils.gup("a") == "c";
    },
    nShowing() {
      return !this.$refs.stbl
        ? this.maxResult
        : (this.$refs.stbl.itemList && this.$refs.stbl.itemList.length) || 0;
    }
  },
  methods: {
    removalMessage(item) {
      return ""; // can be implemented at child level
    },
    reset() {
      Object.assign(this.$data, defaultData());
    },
    confirmRemoval(option) {
      let self = this;
      let item = option?.item || option?.items; // the object entity
      let type = option.type; // the type of entity ("connector" | "device" | "data" | "alarm")
      let rule = option.rule; // the access control rule to be validated

      return new Promise((resolve) => {
        self.validatePrivileges("manage", rule).then((hasAccess) => {
          if (hasAccess) {
            self
              .$swal({
                title: self.$t("are_you_sure"),
                content:
                  self.removalMessage(item) ||
                  self.warningContent(
                    type,
                    item.name,
                    option.hide_warning ? "" : "you_wont_be_able_to_revert_this"
                  ),
                icon: "warning",
                buttons: [self.$t("cancel"), self.$t("yes_delete_it")]
              })
              .then(function (confirmed) {
                resolve(confirmed);
              });
          } else {
            resolve(false);
          }
        });
      });
    },
    removeResources(option, callback) {
      let _cb = callback || function () {};

      this.service.removeMultiple(option).then((ret) => {
        this.$emit("loading", false);
        this.busy = false;
        if (this.validateDeleteResponse(ret)) {
          this.$store.dispatch("user/configureUserContract");
          if (option.resource_key) {
            this.$store.dispatch(
              "dashboard/removeResources",
              option.resource_ids.map((id) => ({
                [option.resource_key]: id
              }))
            );
          }
          this.exitSuccess = true;
          let items = [];
          if (option?.keep_deleted) {
            items = this.items;
            items
              .filter((item) => option.resource_ids.indexOf(item.id) >= 0)
              .forEach((item) => {
                item.deleted_at = new Date();
              });
          } else {
            items = this.items.filter(
              ({id}) => option.resource_ids.indexOf(id) == -1
            );
          }
          this.maxResult = this.maxResult > 0 ? this.maxResult - 1 : 0;
          this.multiSelection.values = [];
          this.$set(this, "items", items);
          this.showAlert();
          _cb(true);
        } else {
          this.showAlert();
          _cb(false);
        }
      });
    },
    validateAndRemove(option, callback) {
      this.confirmRemoval(option).then((confirmed) => {
        if (confirmed) {
          let _cb = callback || function () {};
          let item = option.item; // the object entity
          let service = option.service; // then rest service (ConnectorService | DeviceService | DataService | AlarmService)
          this.busy = true;
          this.$emit("loading", true);
          service.remove(item).then((ret) => {
            this.$emit("loading", false);
            this.busy = false;
            if (this.validateDeleteResponse(ret)) {
              this.$store.dispatch("user/configureUserContract");
              if (option.resource_key) {
                this.$store.dispatch("dashboard/removeResources", [
                  {
                    [option.resource_key]: item.id
                  }
                ]);
              }
              this.exitSuccess = true;
              let items = [];
              if (option?.keep_deleted) {
                items = this.items;
                items.find((obj) => obj.id == item.id).deleted_at = new Date();
              } else {
                items = this.items.filter(({id}) => id != item.id);
              }
              this.maxResult = this.maxResult > 0 ? this.maxResult - 1 : 0;
              this.$set(this, "items", items);
              this.showAlert();
              _cb(true);
            } else {
              this.showAlert();
              _cb(false);
            }
          });
        }
      });
    },
    validateAndMassRemove(option, callback) {
      this.confirmRemoval(option).then((confirmed) => {
        if (confirmed) {
          let _cb = callback || function () {};
          let payload = {
            remove_type: "partial",
            resource_ids: this?.multiSelection?.values,
            contract_id: this?.contract?.id,
            connector_id: this?.connectorId
          };
          let service = option.service; // then rest service (ConnectorService | DeviceService | DataService | AlarmService)
          this.busy = true;
          this.$emit("loading", true);
          service.removeMultiple(payload).then((ret) => {
            this.$emit("loading", false);
            this.busy = false;
            if (this.validateDeleteResponse(ret)) {
              this.$store.dispatch("user/configureUserContract");
              if (option.resource_key) {
                this.$store.dispatch(
                  "dashboard/removeResources",
                  payload.resource_ids.map((id) => ({
                    [option.resource_key]: id
                  }))
                );
              }
              this.exitSuccess = true;
              let items = [];
              if (option?.keep_deleted) {
                items = this.items;
                items
                  .filter((item) => payload.resource_ids.indexOf(item.id) >= 0)
                  .forEach((item) => {
                    item.deleted_at = new Date();
                  });
              } else {
                items = this.items.filter(
                  ({id}) => payload.resource_ids.indexOf(id) == -1
                );
              }
              this.maxResult = this.maxResult > 0 ? this.maxResult - 1 : 0;
              this.multiSelection.values = [];
              this.$set(this, "items", items);
              this.showAlert();
              _cb(true);
            } else {
              this.showAlert();
              _cb(false);
            }
          });
        }
      });
    },
    onCommand(ev) {
      if (this.cloneEnabled) return;
      let self = this;
      if (ev && ev.name) {
        if (ev.name in self && typeof self[ev.name] == "function") {
          self[ev.name](ev);
        } else {
          self
            .$swal({
              title: self.$t("not_allowed"),
              text: self.$t("feature_not_available"),
              icon: "error"
            })
            .then((result) => {
              self.alert = null;
            });
        }
      }
    },
    onSelect(entry) {
      if (entry.deleted_at || this.cloneEnabled) return;
      if (entry.length !== undefined && this.multiSelection.key) {
        this.$set(this.multiSelection, "values", entry);
      } else {
        this.onCommand({
          name:
            this?.select && typeof this.select == "function"
              ? "select"
              : "edit",
          target: entry
        });
      }
    },
    clearSelection() {
      this.multiSelection.values = [];
    },
    newConnector(command) {
      this.$router.push("/dashboard/edit/connector/0");
    },
    newDevice(command) {
      this.$router.push("/dashboard/edit/connector/0/device/0");
    },
    newData(command) {
      this.$router.push("/dashboard/edit/connector/0/device/0/data/0");
    },
    newAlarm(command) {
      this.$router.push("/dashboard/edit/connector/0/device/0/data/0/alarm/0");
    },
    importConnectors() {
      this.importEntity = "connector";
    },
    importDevices() {
      this.importEntity = "device";
    },
    importData() {
      this.importEntity = "data";
    },
    importAlarms() {
      this.importEntity = "alarm";
    },
    clearImportEntity() {
      this.importEntity = "";
    },
    massRemove(command) {
      return; // can be implemented at child level
    },
    onMultiColumnSort(columns) {
      this.reset();
      this.sortOrder = columns;
      this.fetchItems();
    },
    // Perform the api fetch
    onResposeReady(response) {
      if (response && typeof response == "object") {
        let items = this.items || [];
        if (response.length) {
          // merge current list with results
          response.forEach((r) => {
            items = items.filter((i) => {
              return i.id != r.id; // remove if it exists and so only latest version is considered
            });
            items.push(r); // insert;
          });
        }
        if (this.parseItems) {
          items = this.parseItems(items);
        }
        this.maxResult = items.length;
        this.$set(this, "items", items);
      }
    },
    fetch(query) {
      return new Promise((resolve) => {
        this.busy = true;
        this.$emit("loading", true);
        this.service.fetch(query).then((response) => {
          this.busy = false;
          this.$emit("loading", false);
          this.onResposeReady(response);
          resolve(true);
        });
      });
    },
    getResourceById(type, qid) {
      let source = [];
      switch (type) {
        case "connector":
          source = this.$store.getters["dashboard/connectorList"];
          break;
        case "device":
          source = this.$store.getters["dashboard/deviceList"];
          break;
        case "data":
          source = this.$store.getters["dashboard/dataList"];
          break;
      }
      return source.find(({id}) => id == qid);
    },
    getScreenById(value) {
      return this.screenList.find(({id}) => parseInt(id) == parseInt(value));
    },
    download(connector, options) {
      if (!this.service || !this?.contract?.id) return;
      options = options || {};
      var ids = this.nSelected
        ? this.multiSelection.values
        : (this.$refs?.stbl?.filteredList || []).map(({id}) => id);
      options.contract_id = this?.contract?.id;
      if (ids.length) {
        options.ids = ids.join();
        let format = options?.format || "csv";
        if (options?.format) delete options.format;
        var fname = connector
          ? `${this.entity}-connector-${this?.connector?.name || "unknown"}.${format}`
          : `connectors.${format}`;
        this.service.export(
          this.$utils.buildUrlSafe(null, options),
          fname
        );
      }
    },
    downloadCSV(connector) {
      this.download(connector, {});
    },
    downloadBackup(connector) {
      this.download(connector, {backup: true, format: "hpc"});
    },
    resourceImported(response, entity) {
      if (!response) return;
      if (typeof response === "string") {
        this.validateSaveResponse(response);
        this.showAlert();
      } else if (response instanceof Array) {
        // TODO: make it generic
        this.busy = true;
        const eng = {
          connector: {
            adapter: (lst) => {},
            list: "dashboard/connectorList",
            filter: (item) => {
              return true;
            }
          },
          device: {
            adapter: (lst) => {
              var service = new DeviceService();
              service.deviceListAdapter(lst);
            },
            list: "dashboard/deviceList",
            filter: (item) => {
              var connector = item.connector;
              if (!connector && item.connector_id) {
                connector = (
                  this.$store.getters["dashboard/connectorList"] || []
                ).find(({id}) => parseInt(id) == parseInt(item.connector_id));
              }
              return connector &&
                parseInt(connector.id) === parseInt(this.connector_id)
                ? true
                : false;
            }
          },
          data: {
            adapter: (lst) => {
              var service = new EquipmentDataService();
              service.dataAdapter(lst);
            },
            list: "dashboard/dataList",
            filter: (item) => {
              var connector = item?.device?.connector;
              if (!connector && item.clp_id) {
                connector = (
                  this.$store.getters["dashboard/connectorList"] || []
                ).find(({id}) => parseInt(id) == parseInt(item.clp_id));
              }
              return connector &&
                parseInt(connector.id) === parseInt(this.connector_id)
                ? true
                : false;
            },
            updateList: (lst) => {
              let connectorIdList = (lst || [])
                .filter(
                  (item) =>
                    item.device &&
                    item.device.connector.id &&
                    item.device.is_reference
                )
                .map(({device}) => device.connector.id);
              connectorIdList = this.$utils.distinct(connectorIdList);
              connectorIdList.forEach((cid) => {
                this.$store.dispatch("dashboard/fetchResourcesFrom", {
                  connectorId: cid,
                  resource: "data",
                  forceUpdate: true
                });
              });
            }
          },
          alarm: {
            list: "dashboard/alarmList",
            filter: (item) => {
              return (
                item.connector_id &&
                parseInt(item.connector_id) === parseInt(this.connectorId)
              );
            }
          }
        };
        this.validateSaveResponse(response, this.$t("success_import_message"));
        this.showAlert();
        eng[entity].adapter && eng[entity].adapter(response);
        this.$store.dispatch("dashboard/addResource", {
          resource: entity,
          list: response,
          forceUpdate: true
        });
        this.$nextTick(() => {
          let lst = (this.$store.getters[eng[entity].list] || []).filter(
            eng[entity].filter
          );
          if (entity == this.entity) {
            this.onResposeReady(lst);
            if (eng[entity].updateList) {
              eng[entity].updateList(lst);
            }
          }
          this.$emit("resource_imported", entity);
          this.busy = false;
        });
      }
    },
    me() {
      return this;
    }
  },
  created() {
    this.multiSelection.key = this.cloneEnabled ? "" : "id";
    this.$root.$emit("controlSidebar:setContent", null);
    // this.fetchItems();
  }
};
</script>
