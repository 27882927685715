<template>
  <section>
    <FormTextList
      v-if="editing"
      v-bind:text_list="entry"
      v-bind:isModelBased="isModelBased"
      v-on:close="close"
      v-on:save="save"
      v-on:update="update"
      v-on:remove="remove"
    />
    <FormTextListSelection
      v-else
      v-bind:text_list_id="text_list_id"
      v-bind:isModelBased="isModelBased"
      v-model="entry"
      v-on:edit="edit"
    />
  </section>
</template>

<script>
import FormTextList from "@/components/registration/form-text-list.vue";
import FormTextListSelection from "@/components/registration/form-text-list-selection.vue";
export default {
  name: "FormTextListInline",
  components: {
    FormTextList,
    FormTextListSelection
  },
  props: {
    value: {
      type: Number,
      required: false,
      default: () => 0
    },
    isModelBased: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    return {
      text_list_id: 0,
      entry: null,
      editing: false
    };
  },
  computed: {},
  watch: {
    entry(n) {
      if (n) {
        this.dispatchInputEvent();
      }
    }
  },
  methods: {
    dispatchInputEvent() {
      this.$emit(
        "input",
        parseInt(
          (this.entry && this.entry.source && this.entry.source.id) ||
            this.text_list_id ||
            0
        )
      );
    },
    edit(entry) {
      if (entry) {
        this.text_list_id = entry.source.id;
        this.entry = entry;
      } else {
        this.text_list_id = 0;
        this.entry = null;
      }
      this.editing = true;
    },
    save(entry) {
      if (entry) {
        this.text_list_id = entry.id || 0;
        this.dispatchInputEvent();
        this.editing = false;
      }
    },
    update(entry) {
      if (entry) {
        this.text_list_id = entry.id || 0;
        this.dispatchInputEvent();
      }
    },
    remove() {
      this.text_list_id = 0;
      this.entry = null;
      this.dispatchInputEvent();
      this.editing = false;
    },
    close() {
      this.editing = false;
    }
  },
  created() {
    this.text_list_id = this.value;
  },
  mounted() {}
};
</script>

<style scoped></style>
