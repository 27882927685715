<template>
  <section>
    <form role="form">
      <div class="row">
        <div class="form-group col-xs-6">
          <div class="input-group">
            <div class="input-group-addon">{{ $t("name") }}</div>
            <div class="form-control" v-if="busy">
              <i class="fa fa-refresh fa-spin"></i>
            </div>
            <select
              v-else
              class="form-control"
              data-testid="text-list"
              v-model="id"
              v-bind:disabled="
                isModelBased || !$can('manage', 'ListaDeTextoAcesso')
              "
            >
              <option
                v-for="(item, index) in items"
                v-bind:key="index"
                v-bind:value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group col-xs-3 text-left">
          <button
            class="btn btn-gap"
            v-bind:title="$t('edit')"
            v-on:click.prevent.stop="edit()"
            v-bind:disabled="isModelBased"
            v-if="$can('manage', 'ListaDeTextoEscrita') && id"
          >
            <i class="fa fa-pencil"></i>
          </button>
          <button
            class="btn btn-primary "
            v-bind:title="$t('new_text_list')"
            v-bind:disabled="isModelBased"
            v-on:click.prevent.stop="add()"
            v-if="$can('manage', 'ListaDeTextoEscrita')"
          >
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import SettingsService from "@/services/settings.js";
export default {
  name: "FormTextListSelection",
  props: {
    text_list_id: {
      type: Number,
      required: false,
      default: () => 0
    },
    isModelBased: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    return {
      busy: false,
      id: "",
      items: []
    };
  },
  computed: {
    selected() {
      let self = this;
      let lst = (this.items || []).filter(function(i) {
        return i.id == self.id;
      });
      return (lst.length && lst[0]) || null;
    },
    contract_id() {
      return this.$store.getters["user/loggedUser"].contract_id;
    }
  },
  watch: {
    selected: {
      handler(n) {
        if (n) {
          this.$emit("input", n);
        }
      },
      deep: true
    }
  },
  methods: {
    add() {
      this.$emit("edit");
    },
    edit() {
      let entry = this.selected;
      this.$emit("edit", entry);
    },
    fetch() {
      let self = this;
      self.busy = true;
      let srv = new SettingsService();
      let query = {
        contract_id: this.contract_id
      };
      srv.updateTextList(query).then((response) => {
        self.busy = false;
        if (response && response.length) {
          let id = 0;
          let items = [];
          for (var i in response) {
            var entry = response[i];
            var lst = [];
            if (entry.id == this.text_list_id) {
              id = entry.id;
            }
            var default_id = "";
            for (var entry_id in entry.default_item) {
              default_id = entry_id;
              break;
            }
            for (var entry_id in entry.items) {
              lst.push({ id: entry_id, text: entry.items[entry_id] });
            }
            items.push({
              id: entry.id || 0,
              name: entry.name || "",
              items: lst,
              default_id: default_id,
              source: entry
            });
          }
          self.$set(self, "items", items);
          if (!id && items.length) {
            id = items[0].id;
          }
          this.id = id;
        }
      });
    }
  },
  created() {
    this.fetch();
  }
};
</script>

<style scoped>
.btn-gap {
  margin-right: 5px;
}
</style>
