export default {
  data() {
    return {
      mobileThreshold: 768,
      minWidthThreshold: 768,
      maxWidthThreshold: null,
      widthThreshold: null,
      meetWidthThreshold: false,
      isMobile: false
    };
  },
  methods: {
    updateWidthThreshold() {
      let min = this.minWidthThreshold,
        max = this.maxWidthThreshold;
      max =
        typeof this.widthThreshold == "object" && this.widthThreshold != null
          ? this.widthThreshold.max
          : max;
      min =
        typeof this.widthThreshold == "object" && this.widthThreshold != null
          ? this.widthThreshold.min
          : min;

      let result = null;
      if (typeof min == "number") {
        result = window.innerWidth >= min;
      }

      if (result == false || typeof max != "number") {
        this.meetWidthThreshold = result;
      } else if (typeof max == "number") {
        this.meetWidthThreshold = window.innerWidth <= max;
      }

      this.isMobile = window.innerWidth <= this.mobileThreshold;
    }
  },
  mounted() {
    this._observer = new ResizeObserver(this.updateWidthThreshold.bind(this));
    this._observer.observe(document.documentElement);
  },
  activated() {
    this._observer.observe(document.documentElement);
  },
  deactivated() {
    this._observer.unobserve(document.documentElement);
  },
  beforeDestroy() {
    this._observer.disconnect();
  }
};
