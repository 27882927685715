import { pt, en } from "./forms";

// prettier-ignore
export default {
  pt: {
    hints: {
      name: "Nome para identificação do dado no portal",
      description: "Descrição para identificação do dado no portal",
      read_only: "Define&nbsp;se&nbsp;o&nbsp;dado&nbsp;permitirá escrita de valores ou não",
      history: "Habilita&nbsp;o&nbsp;histórico&nbsp;de valores do dado",
      memory_type: "Define como o protocolo será utilizado para obter o valor do dado",
      value_source: "Endereço do dado: valor obtido do endereço da memória.<br>Base local: valor é obtido da base de coleta do dispositivo.",
      memory_address: "Endereço&nbsp;de&nbsp;memória&nbsp;de&nbsp;onde o dado será lido/escrito",
      calculation: "Escolha um cálculo para definir o valor final do dado coletado",
      format: "Formato para apresentação do dado",
      text_list: "Defina associações entre valores e textos para melhorar a exibição dos dados",
      wave_form: 'Representação gráfica dos valores do dado. Se definida, será usada como padrão em gráficos utilizados no dashboard.',
      color: 'Cor a qual será usada para representar o dado em certos locais do dashboard.',
      enable_history: 'Habilite o Histórico para utilizar essa função',
      option_quota_warning: "A opção <b>{item}</b> poderá levá-lo a atingir mais rapidamente o limite de registros no histórico e novos registros poderão ser descartados"
    },
    error_labels: pt
  },
  en: {
    hints: {
      name: "Name for identification of data on the portal",
      description: "Description for identification of data on the portal",
      read_only: "Defines&nbsp;if&nbsp;the&nbsp;data&nbsp;will&nbsp;allow writing values or not",
      history: "Enables data values history",
      memory_type: "Defines how the protocol will be used to get the data value",
      value_source: "Data address: value is get from the memory address.<br>Local base: value is get from the device collect base",
      memory_address: "Memory address from which the data will be read/written",
      calculation: "Choose a calculation to define the final value of the collected data",
      format: "Format to display the data",
      text_list: "Define associations between values and texts to improve the data display",
      wave_form: 'Graphical representation of data values. If defined, it will be used as the default in graphs used in the dashboard.',
      color: 'Color that will be used to represent the data in certain places on the dashboard.',
      enable_history: 'You must enable History option to use this function',
      option_quota_warning: "The selected option <b>{item}</b> may help you reach the limit of records in the history more quickly and new records may be discarded"
    },
    error_labels: en
  },
};
